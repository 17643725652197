<template>
  <div class="home">
    <div class="item-text">
    <h3>Welcome To People's Place Names</h3>
    </div>
    <div class="item-img">
      <img src="../assets/img/map.png">
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
  }
}
</script>

<style scoped>
  .home {
    background-color: #aad3df;
    overflow: auto;
    height: 100%;
    display: flex;
    justify-content: right;
  }
  .item-text {
    flex-shrink: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
  }
  h3 {
    margin: 0;
    /* width: 40%; */
    
    color: white;
    background-color: var(--dark);
    width: fit-content;
    padding: 1em;
    font-size: 2em;
  }
  .item-img {
    flex-shrink: 1;
    width: 50%;
    padding: 1em;
  }
  img {
    /* width: 50%; */
    height: 100%;
    width: auto;
    /* border: 1px solid black; */
    
  }
</style>
