<template>
  <div class="NotFound">
    <div class="item-text">
    <h3>404 Page not found</h3>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'NotFound',
}
</script>