<template>
  <div id="app">
    <div class="app-flexbox">
      <div class="flex-nav">
      <nav-bar/>
      </div>
      <div class="flex-content">
    <router-view/>
    </div>
    </div>
  </div>
</template>

<script>
import NavBar from './components/NavBar'
export default {
  name: 'App',
  components: {
    NavBar,
  },
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

html,body,#app,.app-flexbox {
  height: 100vh;
}

.app-flexbox {
  display: flex;
  flex-direction: column;
}

.flex-content {
  height: 100%;
  overflow: hidden;
}

.flex-nav {
  flex-shrink: 0;
}
</style>
